<template>
  <div class="container" v-title :data-title="$t('i18n.batchElevatorParts')">
    <div id="outer-title">{{ $t("i18n.batchElevatorParts") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.update')"
      v-model="dialogUpdateVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="updateColumns"
        @onSubmit="onUpdateSubmit"
        @resetForm="dialogUpdateVisible = false"
      ></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.historicalUpdate')"
      v-model="dialogHistoryVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-timeline v-if="updateHistory.length > 0">
        <el-timeline-item
          v-for="(activity, index) in updateHistory"
          :key="index"
          :timestamp="activity.timestamp"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div v-else>{{ $t("i18n.noHistoricalData") }}</div>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "BatchElevatorParts",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "mountingsName",
          label: "partName",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "select",
          props: { label: "elevator_number", value: "elevator_number" },
          data: [],
        },
        {
          prop: "dischargeDate",
          label: "manufactureDate",
          align: "center",
        },
        {
          prop: "replacementDate",
          label: "updateDate",
          align: "center",
        },
        {
          prop: "renewalReason",
          label: "updateReason",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: proxy.$defined.WIDTH > 768 ? 240 : 100,
        fixed: "right",
        list: [
          {
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加素材"),
            type: "outer",
            method: () => {
              let routeData = router.resolve({
                path: "/detection/addBatchElevatorParts",
              });
              window.open(routeData.href, "_blank");
            },
          },
          {
            label: "update",
            icon: "el-icon-edit",
            show: true,
            type: "inner",
            method: (index, row) => {
              state.dialogUpdateVisible = true;
              state.updateColumns[0].value = row.id;
              state.updateColumns[1].value = row.elevatorNumber;
              state.updateColumns[1].show = true;
              state.updateColumns[2].value = row.mountingsName;
              state.updateColumns[2].show = true;
            },
          },
          {
            label: "historicalUpdate",
            icon: "el-icon-view",
            show: true,
            type: "inner",
            method: (index, row) => {
              proxy.$api.detection.getHistoryByPartId(row.id).then((res) => {
                res.data.map((item) => {
                  item.timestamp = item.renewalDate;
                  item.content =
                    t("i18n.updateReason") +
                    "：" +
                    item.renewalReason +
                    "（" +
                    item.createdBy +
                    "）";
                });
                state.updateHistory = res.data;
                state.dialogHistoryVisible = true;
              });
            },
          },
          {
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            type: "inner",
            method: (index, row, pages) => {
              handleDelete(row, pages);
            },
          },
          {
            label: "batchUpdate",
            icon: "el-icon-edit",
            class: "transparent",
            show: true,
            type: "outer",
            method: (formInline) => {
              state.formInline = JSON.parse(JSON.stringify(formInline));
              if (checkSelectPart("selectNewData")) {
                state.dialogUpdateVisible = true;
                state.updateColumns[1].show = false;
                state.updateColumns[2].show = false;
              }
            },
          },
          {
            label: "batchDelete",
            icon: "el-icon-delete",
            class: "import",
            show: true,
            type: "outer",
            method: (formInline) => {
              state.formInline = JSON.parse(JSON.stringify(formInline));
              handleMulDelete(state.selectNewData);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      dialogUpdateVisible: false,
      updateColumns: [
        {
          prop: "id",
          show: false,
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "mountingsName",
          label: "partName",
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "replacementDate",
          label: "updateDate",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "renewalReason",
          label: "updateReason",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      selectNewData: [],
      dialogHistoryVisible: false,
      updateHistory: [],
    });

    const init = async (params) => {
      state.loading = true;
      params.isTrouble = false;
      const { data } = await proxy.$api.detection.getQueryPartPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const getElevators = async () => {
      let { data } = await proxy.$api.detection.getElevatorByPart();
      state.columns.map((item) => {
        if (item.prop === "elevatorNumber") {
          item.data = data;
        }
      });
    };
    getElevators();

    const handleSelectionChange = (val, key) => {
      let arr = [];
      val.map((item) => {
        arr.push(item.id);
      });
      state[key] = arr;
    };

    const handleDelete = (row) => {
      let ids = [row.id];
      handleMulDelete(ids);
    };

    const handleMulDelete = (ids) => {
      if (ids.length === 0) {
        proxy.$defined.tip(t("i18n.selectPart"));
        return false;
      }
      proxy.$defined.confirm(
        () => {
          proxy.$api.detection.delPartByElevator(ids).then(() => {
            init(state.formInline);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    const onUpdateSubmit = async (row) => {
      for (let key in row) {
        if (row[key] instanceof Date) {
          row[key] = proxy.$defined.format(row[key]);
        }
      }
      if (row.id) {
        await proxy.$api.detection.editPartByElevator(row.id, row);
        proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
        state.dialogUpdateVisible = false;
        init(state.formInline);
      } else {
        var arr = [];
        state.selectNewData.map((item) => {
          let obj = {
            partId: item,
            replacementDate: row.replacementDate,
            renewalReason: row.renewalReason,
          };
          arr.push(obj);
        });
        await proxy.$api.detection.mulEditPartElevator(arr);
        proxy.$defined.tip(
          t("i18n.batchUpdate") + t("i18n.success"),
          "success"
        );
        state.dialogUpdateVisible = false;
        init(state.formInline);
      }
    };

    const checkSelectPart = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectPart"));
        return "";
      }
    };

    return {
      ...toRefs(state),
      init,
      onUpdateSubmit,
      handleSelectionChange,
      checkSelectPart,
    };
  },
};
</script>
